@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("sora-latin-ext-200-normal.cb999347.woff2") format("woff2"), url("sora-all-200-normal.d2434b00.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("sora-latin-200-normal.2e42dadf.woff2") format("woff2"), url("sora-all-200-normal.d2434b00.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("sora-latin-ext-400-normal.0e748bfe.woff2") format("woff2"), url("sora-all-400-normal.c4b563ef.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("sora-latin-400-normal.b898ab4e.woff2") format("woff2"), url("sora-all-400-normal.c4b563ef.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("sora-latin-ext-600-normal.bb14c76e.woff2") format("woff2"), url("sora-all-600-normal.72f00705.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("sora-latin-600-normal.2ef50f1f.woff2") format("woff2"), url("sora-all-600-normal.72f00705.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("sora-latin-ext-800-normal.49f43c62.woff2") format("woff2"), url("sora-all-800-normal.3428fab5.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Sora;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("sora-latin-800-normal.c852fb3c.woff2") format("woff2"), url("sora-all-800-normal.3428fab5.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.zYKFtW_boxShadow {
  box-shadow: 2px 2px 3px #ccc;
}

.zYKFtW_moveBox {
  z-index: 2;
  right: 10px;
  top: var(--chakra-sizes-16);
  text-align: center;
  color: #fff;
  background: none;
  padding: 0;
  transition-duration: .2s;
  transition-timing-function: ease-in;
  position: absolute;
}

.zYKFtW_scaleVal {
  background: var(--chakra-colors-teal-500);
  border-radius: 4px;
  height: 20px;
  margin-top: 4px;
  margin-right: 10px;
}

.LlCHRG_fileLoadInput {
  display: none;
}

.LlCHRG_fileLoadLabel {
  height: var(--chakra-sizes-14);
  background: var(--chakra-colors-teal-500);
  color: #fff;
  cursor: pointer;
  padding: 14px 25px;
  font-size: 1em;
  transition: all .4s;
  display: block;
}

.KUWBPq_aside {
  z-index: 99;
}

._0MxhUG_boxShadow {
  box-shadow: 2px 2px 3px #ccc;
}

._0MxhUG_zoomBox {
  z-index: 100;
  text-align: center;
  color: #fff;
  background: none;
  padding: 0;
  transition-duration: .2s;
  transition-timing-function: ease-in;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

._0MxhUG_scaleVal {
  background: var(--chakra-colors-teal-500);
  border-radius: 4px;
  height: 20px;
  margin-top: 4px;
}
/*# sourceMappingURL=index.b8ffb19d.css.map */
